export const content = [
  {
    id: 1,
    header: 'Початок усіх починань…',
    route: 'the-beginning-of-the-all-endeavors',
    text: [
      'Легенда ця бере свій початок з глибини століть, коли час плинув повільніше, хоробрі герої вершили видатні події та існували маги-чародії. То були славні часи змін та великих звершень.',
      'Жили в нашому Підгірному краї пліч-о-пліч Гуцули, Хоббіти, Обінорці, народи Суніго та багато інших. Мали доста важливих справ та відповідальних доручень короля Підгірного краю - Сімбельріна VIII.',
      'Селище, що розкинулось в цій місцині було оседком ремеслярів, землеробів і чародіїв під управлінням нашого головного героя - пана Гедзьо Бурбеля.',
      'Обіймав він посаду, що іменувалась - «Головний Радник Найважливіших Доручень Королівства Алвентар та Гельверд II ступеню магічних діянь». І був він мером  Підгірного краю. Про яке, мій любий друже, надалі і піде оповідь.',
      'Гедзьо Бурбель був всіма шанований та завжди бажаний гість у кожного жителя королівства. Він був мудрим радником, що мав відповіді на всі питання, допомагав у виконанні доручень всім жителям. Підказував також і чародіям для яких справ, яку магію краще застосовувати.',
      'Завдяки знанням та порадам Гедзьо, вершились такі важливі справи, як зрушення русла могутньої річки Прут, для виходу в море Сангард; копання печери для здобутків цінних порід каменю та кристалів гірського Тевтонію. Той був, як кажуть в легендах, потрібний для створення магічних порталів, що давали змогу чародіям користуватися надзвичайно потужними силами для виконання добрих та корисних справ.',
      'Ще багато чого відбувалося в ті часи: менш видатного, але не менш цікавого та захоплюючого. Про що і розповідають нам легенди та оповіді, які зберегли і переповіли для нас попередні покоління!',
      'За легендою селище було закладено в цьому місці Хобітами разом з молодим ще тоді, 140 річним Гедзьо Бурбелем. За розпорядженням, правлячого в ті часи короля Сімбельріна І, необхідно було відшукати найбільш вдале місце для створення нового русла річки Прут, щоб мати можливість вийти в море Сангард. Налагодити торгівлю, постачання риби та морської солі з жителям Хазгарду, що на берегах Сангарду.',
      '34 місячні ночі  Хобіти та Гуцули (які вирішили теж допомогти в цій справі) домовлялись з річкою Прут про можливість зрушити русло. А на 35-ту ніч Гедзьо, звернувшись до Прута, разом з верховними чародіями отримав дозвіл. І було закладено селище, що зветься Обінор. І почалося заселення селища мешканцями і розпочалася кропітлива праця. Але це вже інша легенда, яку ми обов’язково розповімо згодом.',
      'Спершу давайте познайомимось з нашими найближчими друзями  - жителями селища Обінор. Їх побутом, оселями, звичками та справами.',
      'Отже за головною брамою селища всіх зустрічає Головна площа. Гостей, посланців, мандрівників завжди раді бачити тут, білля вогнища. Що зігріває та очищує, надає сил! Вогнище це не згасає поколіннями, від того самого дня, коли було закладено селище.',
      'Тут же, на площі, прошу завітати у кафе дядька Джунджика. Облаштованого під великим капелюхом гігантів народу Суніго, що лишили колись його в дарунок Хобітам та Обіноріцям. Скуштуйте дуже смачних став з вогню. Місцеві господарства постачають Джунджику найкращі овочі, рибу та м’ясо для його кухні!',
    ],
    background: './img/bg_01.jpg'
  },
  {
    id: 2,
    header: 'Магічний тунель бажань',
    route: 'magic-tunnel-of-wishes',
    text: [
      'У мальовничому поселенні хобітів, що зветься Обінор, був тунель, який усі місцеві жителі називали Тунелем Бажань. Цей тунель був прихований у затишному куточку лісу, оточений високими деревами та густими кущами. Легенди стверджували, що той, хто пройде через нього з чистим серцем, зможе здійснити найзаповітніше бажання.',
      "Молодий хобіт на ім'я Лорас завжди мріяв відправитися в далекі подорожі, пізнати нові світи і знайти своє місце в житті. Його душа прагнула пригод, але родина і друзі вважали це нерозсудливим, радячи йому залишитися вдома та садити городину біля хати Гедзьо.",
      "Одного разу, під час прогулянки лісом, Лорас натрапив на старий камінь з висіченими на ньому давніми знаками. Він зрозумів, що це вказівник до Тунелю Бажань. Сповнений рішучості, хобіт вирішив пройти через нього, аби здійснити свою мрію.",
      "Коли Лорас увійшов у тунель, його оточили дивовижні світла та тіні, які грали перед очима, ніби у танці. Він почув шепіт, що підказував йому йти далі. Кожен крок наповнював його новими силами і вірою в себе. Дійшовши до середини тунелю, Лорас побачив перед собою старого лісового ельфа, який усміхнувся і сказав:",
      "— Твоє бажання здійсниться, Лорасе. Ти знайдеш своє місце у світі, але пам’ятай, що найголовніше – вірити у себе і ніколи не здаватися.",
      "Відтоді життя Лораса змінилося. Він вирушив у подорожі, відвідав безліч місць, познайомився з різними народами і знайшов багато друзів. У його серці завжди була віра у диво Тунелю Бажань, що допоміг йому здійснити мрію. Про свої подорожі та нові знайомства він видав сотні книг, які й зараз є в нашій бібліотеці і з якими Ви можете ознайомитися. Адже кожен автор завжди спочатку мав лише мрію і трішечки віри у свої сили.",
      "Головне йти до своїх мрій з чистим серцем і відкритою душею."
    ],
    background: './img/bg_02.jpg'
  },
  {
    id: 3,
    header: 'Ватра Тролів',
    route: 'bonfire-of-trolls',
    text: [
      "У глибині лісу, біля високих скель і смарагдових дерев, існувала Варта Тролів. Кажуть, що вона була ще до того як вперше місяць зійшов на небо. Це було загадкове місце, де за легендами збиралися тролі, охоронці стародавніх скарбів і таємниць.",
      "Вдень вони були частиною скелі і перетворювалися на брили каміння. А вночі ці брили оживали та сідали біля ватри і ділилися усіма таємницями, які чули за день від людей, що проходили повз них. Ватра була невидима для більшості, адже тролі вміли чаклувати, ховаючи її від небажаних гостей, які несли в собі злі думи.",
      "Але тим, хто володів чистим серцем і сміливістю, відкривалася стежка, що вела до Ватри, освітлена місячним сяйвом і чарівними світляками."
    ],
    background: './img/bg_03.jpg'
  },
  {
    id: 4,
    header: 'Ельфійська гойдалка',
    route: 'elf-swing',
    text: [
      "Під величною скелею, де дві височезні смереки стрімко здіймалися до неба, висіла ельфійська гойдалка, що стала легендою серед місцевих жителів. Ця гойдалка, виготовлена зі сріблястих ниток, що ельфи змотали з місячних промінчиків, які ввечері простягалися до Кришталевого озера.",
      "Вона сяяла вночі, мов зірка, що впала з небес, хоча подейкують, що ланци на яких вона висить, зроблені з металу зірки, що впала. Її створили ельфи для того, щоб дарувати мандрівникам мить незабутньої радості та натхнення. Кажуть, що кожен, хто сідав на цю гойдалку, відчував легкість і безмежну свободу, ніби сам ставав частиною чарівного світу.",
      "Одного разу, коли нічне небо було всипане зорями, молодий хобіт на ім’я Арвен, відомий своєю добротою і допитливістю, знайшов цю гойдалку. Сівши на неї, він відчув, як гойдалка підняла його високо в повітря, вище смерек, все навколо стало дрібним і запалало чарівними вогниками.",
      "Повернувшись на землю, він зрозумів, що отримав дар бачити красу в кожній миті життя. Арвен став носієм ельфійської мудрості, передаючи цю магію іншим, і тепер усі знали, що той, хто торкнеться гойдалки, відкриє для себе новий, чарівний світ."
    ],
    background: './img/bg_04.jpg'
  },
  {
    id: 5,
    header: 'Борфей. Легенда про магічну риболовлю',
    route: 'the-legend-of-borfey',
    text: [
      "Хобіт на ім'я Борфей жив поруч із грибарем Дармілом у затишній хатині, в якій завжди було чути шум моря та відчувалося легке покачування хвиль. Поруч з його будиночком, як нагадування про його минуле життя, мерехтіло Кришталеве озеро. Подейкують, що в минулому Борфей жив біля найбільго в світі водоспаду, та чи від шуму падаючої води, чи то від старості оглух трішки і вирішив відселитися в більш спокійне місце. Таким прихистком для нього став Обінор.",
      "Борфей мав одну велику пристрасть – риболовлю. Щоранку він виходив до ставка з вудкою та кошиком, сподіваючись впіймати щось особливе.",
      "Одного разу, коли Борфей знову рибалив, він помітив, як вода в ставку почала булькотіти і хвилюватися. Зненацька з глибини виринула величезна рибина з блискучими лусками, які відбивали сонячні промені. Це була Лускряна – легендарна риба, яку ніхто не міг упіймати протягом століть.",
      "Лускряна почала кидатися у всі боки, намагаючись вирватися з гачка. Борфей, незважаючи на свій маленький зріст, виявив неабияку сміливість і витримку. Він швидко зрозумів, що звичайною вудкою не зможе її втримати. Хобіт схопив свою міцну сітку, яку він сам сплів минулої зими, і підкинув її над рибою.",
      "Сітка впала точно на Лускряну, і рибина затихла, зрозумівши, що більше не може боротися. Борфей обережно витягнув рибу на берег, де вона мирно заспокоїлася. Хобіт хотів її сфотографувати, аби потім хизуватися перед своїми односельчанами, аби ті бачили свідком якого рідкісного скарбу він став. Та тільки взяв до рук фотоапарат - він побачив цей пронизливий погляд. Він зрозумів, що вже десь бачив ці очі. Саме так! Так на нього з триста років тому дивилася водяна німфа, яка була хранителькою ставка.",
      "Виявилося, що Лускряна була зачарована злим чаклуном, який наклав закляття на ставок. Вона просила Борфея зняти чари, щоб повернути спокій і гармонію в ці місця та між іншим її справжній вигляд: срібні довгі плавники, золотаве волосся і глибокі очі, кольору морських хвиль.",
      "Хобіт не вагався і вирушив у мандрівку до темного лісу, де жив чаклун. Пройшовши через густі хащі і подолавши безліч перешкод, Борфей нарешті дістався до лігва чаклуна. За допомогою свого розуму і хитрощів він зміг обдурити його і отримати від нього заклинання для зняття прокляття з водяної німфи.",
      "Повернувшись до ставка, Борфей вимовив магічні слова і вода засяяла яскравим бірюзавим світлом. Лускряна звільнилася від закляття і вдячно одарувала його морськими раковинами, що звучать ніби морські хвилі, які розбиваються від вітру в скелі. Тут вона зникла у глибині Кришталевої води.",
      "З того дня Кришталеве озеро стало ще красивішим, а Борфей – справжнім героєм свого поселення. Він продовжував рибалити, але тепер кожен улов був для нього нагадуванням про той день, коли він здійснив свій подвиг і врятував водяну німфу.",
    ],
    background: './img/bg_05.jpg'
  },
  {
    id: 6,
    header: 'Харамбор',
    route: 'who-is-harambor',
    text: [
      "Розпочнемо нашу оповідь з того, що Харамбор, це гном, який володіє старовинною магією. Він вміє розмовляти з деревами, кущами – усією флорою. Кажуть, що його батьківщина – це далекий острів біля Нової Зеландії, увесь вкритий густою зеленню, де кожне дерево має своє ім’я та більше того – чи то душу, чи розум. Тобто вони там з ними балакають, разом готуються до свят і веселяться, а ще уявляєте ці гноми читають деревам книги та потім разом обговорюють, обмінюються мудрістю.",
      "Одного дня, Харамбор як завжди обрав у бібліотеці найзахопливіший пригодницький роман і днів вісім читав його без зупину для смарагдової ….. Та настільки захопилася пригодами головного героя, що почала після цього впадати у відчай, що існують створіння, які мають можливість подорожувати, бачити нові країни і світи, а вона не може цього робити, бо глибоко впустила коріння у землю. ",
      "Та Харамбор запропонував вихід, він відщепив паросток від того дерева та сказав, що покаже світ йому, вони стануть учасниками дивовижних пригод. Так він і зробив, наступного ж дня посадив молодий паросток у горщик та попрямував туди куди очі гляділи. Скільки ж дивовижних історій трапилося з ними дорогою.",
      "За тисячі кілометрів їх наздогнала втома. В той час Харамбор вже познайомився з Гедзьо Бурбелем і вони вирішили спільними зусиллями покласти початок поселенню з нетиповими жителями. А той паросток, якому Харамбор вирішив показати світ вже перетворився у кремезне дерево, яке росте й зараз над бібліотекою у Обінорі. Хотілося б з ним побалакати та почути де воно встигло побувати за ці роки, та поки що воно спить після тривалої пригоди. А якщо Ви запитаєте де Харамбор, то саме зараз він десь тут, у Карпатському лісі, допомагає смереці зібратися духом і зсунутися на декілька метрів, бо все життя росте над урвищем у Микулинецькому каньйоні.",
    ],
    background: './img/bg_06.jpg'
  },
  {
    id: 7,
    header: 'Оповідь про хобіта-грибаря',
    route: 'a-story-about-a-hobbit-mushroom-picker',
    text: [
      "На околиці крислатих карпатських лісів, де могутні дерева зводяться з небом, жив хобіт на ім'я Дарміл. Дарміл був невеликим на зріст, з каштановими кучерями і блискучими очима, що світилися добротою. Його головною пристрастю було збирання грибів, і він був відомий довкола як найкращий грибар. Але цього разу ліс приготував для нього щось більше, ніж просто грибний кошик.",
      "Одного ранку, коли сонце тільки-но почало пробиватися крізь густий туман, Дарміл вирушив у свої звичні грибні місця. Але цього разу щось було не так. Ліс здавався тривожним, ніби передчував небезпеку. Дарміл, озброєний лише своїм грибним ножем і великим кошиком, почав свій пошук.",
      "Несподівано він побачив сліди, яких раніше ніколи не зустрічав. Вони вели до глибокої ущелини, де ніколи не бував раніше, попід скелею Довбуша. Відчуваючи щось неладне, Дарміл вирішив йти тими слідами з цікавості, кому ж як не йому бути на варті карпатського лісу. Сліди привели його до старої, забутої печери, що ховалася серед густої рослинності. Хобіт обережно підійшов до входу і зазирнув усередину.",
      "Там він побачив те, на що навіть ніколи не розраховував у своєму житті. А з печери тієї простягувалися язики полум'я кожних декілька хвилин. Спантеличений, але в душі безстрашний, він все ж вирішив зазирнути глибше. Посеред чималої печери сопів справжнісінький дракон! Червона луска, ікла розміром на три горошини менші від зросту Дарміла, хвіст довжини з водоспад Пробій, а з ніздрів іскрився вогонь. Ляк охопив малого хобіта. Схопився навтіки, летів лісом та перечіпався за лежачі зруби. Думав, що от-от сповістить Обінор про страшне диво, що оселилося за крок від їхнього села.",
      "Поки біг, то думав:",
      "- Як? Як таке можливо? Драконів у Карпатах з часів смарагдового місяця не було. Всі вони ще колись покинули цей край, хоча колись Гедзьо розповідав, що Верховинський Кігтеріз заснув десь у густому лісі та його століттям ніхто не бачив.",
      "Стоп. Зупинився.",
      "- Куди біжу? Навіщо біжу? Що це дасть найголовніше?",
      "Запинився і почав у роздумах ходити і бубніти собі під ніс, від смереки до бука.",
      "- І що зараз прибіжу в Обінор, наш Грибмідь під керівництвом інших хобітів в обладунках вирушить на пошуки дракона. Знайдуть і що…вся слава дістанеться їм? Ні, а що мені тоді зостанеться? А з драконом, що зроблять? Ой лишенько, що ж тільки вони можуть з ним зробити. Треба самому. Бо ж шкода його - створіння ж небесне.",
      "Набравшись сміливості, впертою ходою Дарміл рушив назад до печери.",
      "Зазирнув здалеку – тихо, підійшов ближче – побачив як палена вуглинка пролетіла повз його вухо і йому стало знову не пособі. Але треба діяти.",
      "Затамувавши подих, Дарміл навшпиньках проліз в глибочінь печери, аж до самісіньких драконячих ніздрів.",
      "- Фух, тут, але що ж далі?",
      "В цю ж мить як тільки хобіт ледь торкнувся драконячого чола відкрилося біля нього гігантське око, завбільшки з човен. Страх перестав просто існувати. Це була кульмінація для обох.",
      "- Жар! От причина драконячого пробудження, ось чому пихкотить вогнем з печери. Цей лускатий захворів. З драконячого ока покотилася сльоза розміром з гарбузик.",
      "Дарміл зрозумів, що мусить щось зробити, і швидко придумав план.",
      "Повернувшись до своєї хатинки, Дарміл приготував особливу настоянку з рідкісних грибів, яка надає сил. Озброєний сміливістю і настоянкою, він вирушив назад. Два дні і три ночі Дарміл відпоював іклатого своєю грибною юшкою. Не присідав ні на хвилину, все варив тай й варив свій грибний еліксир. А дракон смиренно лежав та підкорювався наказам свого спасителя. Чим більше грибної юшки той пив, то дужчим ставав і жар зникав.",
      "Дарміл зрозумів, що бідолаха прокинувся в печері від застуди після вогкої зими. Та не міг ніяк знову заснути через жар, який його охопив. Провівши п'ять місячних ночей з Ікланем, так його назвав Дарміл, той мирно засопів. Мабуть йому знову почали снитися блакитні небокраї та білі хмаринки, що час від часу осідали в лісі, щоб потішити лускатого.",
      "Дарміл повернувся до своєї хатинки, втомлений, але задоволений. Він знав, що захистив свій рідний ліс і всіх його мешканців. Знав, що врятував Ікланя від хвороби і Обінорців від небезпеки. І відтоді, кожен, хто заходив у ті ліси, чув легенду про сміливого хобіта, який врятував спокій Обінору та перш за все надав руку допомоги навіть незнайомому, навіть досить грізному, але все ж хорошому створінню.",
    ],
    background: './img/bg_07.jpg'
  },
  {
    id: 8,
    header: 'Лемонти',
    route: 'lemonts',
    text: [
      "З появою Лемонтів пов’язана легенда як Гедзьо хотів закоркувати літо.",
      "Ця загадка частенько не давала спокою Гедзьо, коли він смачно посьорбував запашний чай на гойдалці під розкішним деревом. Залишалося ще декілька тижнів, а відповіді все ще не було. Тоді хобіт вирушив по допомогу до високогірного поселення Суніго, яке розкинулося високо в горах. Їх особливістю було бачити надзвичайне в простому, те що зазвичай приховане від людських очей.",
      "Старійшина Суніго, помітивши чисті помисли допитливого хобіта, дав йому магічний еліксир, краплина якого перетворить бажане на дійсне. Тільки-но Гедзьо повернувся додому після тривалої подорожі, одразу побіг до свого улюбленого смарагдового дерева, яка вже майже вкрилося золотом. На ньому залишилося лише два десятки зелених листочків. Старий дістав чарівне зілля, крапну на останні зелені плями в кроні й в ту ж мить вони замайоріли яскравими смарагдовими крилами перед його очима. Те листя перетворилося на папуг, які навіть у найлютішу зиму будуть додавати барв у його життя.",
      "Тож мудрий хобіт закликає бачити дивовижне у простих речах.",
    ],
    background: './img/bg_08.jpg'
  },
  {
    id: 9,
    header: 'Грибмідь',
    route: 'grimmbear',
    text: [
      "Якщо Ви коли-небудь збирали в лісі гриби, знайте – Ви йшли слідом Грибмедя. Напевне ж чули, що ліс карпатський – то магічний край, тут кожен кущ і дерево перегукуються завдяки шепоту вітру. Тож вони й переказали цю легенду нам.",
      "Ще тільки-но хобіти оселилися серед гір Карпат, збудували перші свої домівки, як один з жителів пішов на пошуки лісових дарів, аби наповнити свій погріб до зими смачними запасами. Усе б нічого, якби його жадоба не зіграла злий жарт. Чим далі він заходив у ліс – тим більше грибів ставало в кошику, та на жаль підступна місцевість заплутала його шлях додому.",
      "Вже перші сніжинки торкнулися землі, а він все ще блукав густим лісом. Згорнувшись під деревом, аби придрімати, йому наснився сон, у якому якась лісова сила йому повідомила, що шлях додому він знайде як тільки висадить усі гриби на свої місця. Щоб хобіт не замерз у холодну зиму, перетворила його на ведмедя, а гриби з кошика розсіяла по його хутрові, аби легше було сіяти їх серед дерев.",
      "Тож досі Грибмідь ходить лісом і допомагає грибарям вчасно зупинитися і повернутися додому. Історія, яка вкрилася пилом століть, але навчає досі важливому – всьому мати міру.",
    ],
    background: './img/bg_09.jpg'
  },
  {
    id: 10,
    header: 'Жабокрил',
    route: 'toadfly',
    text: [
      "Ця історія про те, як дивовижні пригоди та складні випробування породжують справжню дружбу. Ще бувши юнаком, Гедзьо вичитав у Нескінченній бібліотеці, що десь у Високогірному краю можна знайти фантастичне поселення, де усі казки стають реальністю, а знайти його можна подолавши Чорногору – хребет дракона, що розкинувся у Карпатах.",
      "Та під силу це було не кожному. Зголосив Гедзьо про свій намір друзям, кільканадцять навіть забажали рушити разом з ідейником. ",
      "Йшли роки, кількість поплічників танула щодня. Зрештою, як розповідають довгі оповіді, подолавши сотні випробувань, поруч з хоробрим хобітом залишилося одне крихітне дивне створіння – жабеня, що вміло високо літати та глибоко пірнати, що не покинуло Гедзьо ні в піднебессі, ні озерній глибочіні.",
      "Однієї серпневої ночі, сидячи біля ватри, над друзями пролетіла зірка, іскра якої впала у вогонь. Вони зрозуміли, що тепер саме ця ватра дає початок новому селищу, де будуть оживати легенди, які створюватимуть разом два давніх товариші. І не важливо, що один може вміститися на долоню іншого. Важливіше, що у нього є велике серце та підтримка.",
    ],
    background: './img/bg_10.jpg'
  },
  {
    id: 11,
    header: 'Вежа Едельрона',
    route: 'the-elderons-tower',
    text: [
      "У маленькому селищі хобітів, Обінорі, схованому серед зелених пагорбів і густих смерекових лісів, жив мудрий хобіт на ім'я Едельрон. Вечорами, коли все село поринало у сон, Едельрон вилазив на вершину скелі Йорч та розташовував там свій телескоп. Він годинами спостерігав за зірками, вивчаючи їхні візерунки і шукаючи у них відповіді на свої запитання. Не одну мудру книгу вони допомогли йому написати.",
      "Серед усіх зірок особливої уваги удостоювалася одна маленька зірочка на ім'я Ісільдра. Вона була його давнім порадником і другом. Від інших зірок Ісільдра відрізнялася тим, що могла говорити і передбачати майбутнє. Едельрон завжди прислухався до її слів, знаючи, що Ісільдра ніколи не підведе.",
      '"Едельроне, мій друже, слухай уважно, – промовила зірочка. – Велика повінь наближається до Обінору. Ріка вийде з берегів і принесе багато лиха. Але у тебе є час підготуватися і врятувати селище".',
      "Едельрон негайно вирушив до Гедзьо Бурбеля і розповів про попередження Ісільдри. Спочатку головний хобіт сумнівався, але знання і авторитет Едельрона переконали його. Відтак, усі жителі селища, під керівництвом Едельрона, почали підготовку. Вони зміцнювали береги річки, будували дамби та розділили під керівництвом Харамбора одне гирло річки на дев'ять менших, таким чином утворивши декілька менших річок з однієї бурхливої.",
      "Коли настала ніч, яку передбачила Ісільдра, сильний дощ обрушився на селище. Річка піднялася і спробувала прорвати захисні споруди, але дійшовши до розгалуження, її хвилі вдарилися об брили каміння і розбилися на дев’ять різних потоків. Вода пішла іншими шляхами, не завдавши значної шкоди.",
      "Жителі були безмежно вдячні Едельрону і його вірній зірочці Ісільдрі. Вони зрозуміли, що завжди повинні прислухатися до мудрих порад і довіряти дружбі. Історія про Едельрона та Ісільдри стала легендою, яку передавали з покоління в покоління.",
    ],
    background: './img/bg_11.jpg'
  },
  {
    id: 12,
    header: 'Мінтаври',
    route: 'the-mints',
    text: [
      "У чарівному селищі, розташованому серед величних гір, жив хобіт на ім'я Фурдель. Він був відомий своїм спокоєм і вмінням піклуватися про тварин, в тому числі і про овечок. Його вівці завжди мали густу, м'яку шерсть, яка виблискувала на світлі і вони улюбленцями всіх місцевих жителів.",
      "Щоранку Фурдель піднімався в гори разом з вівцями, щоб знайти найсоковитішу зелень для своїх улюбленців. Одного разу, під час однієї з таких прогулянок, він натрапив на дивне місце. Це була галявина, оточена старими деревами, які ніби випромінювали таємничу енергію. У центрі галявини знаходився великий камінь, вкритий древніми написами.",
      "Вівці, відчувши магію, почали бігати навколо каменя і блеяти, ніби закликаючи Фурделя підійти ближче. Хобіт, керуючись інтуїцією, наблизився до каменя і торкнувся його поверхні. Раптом від нього вихопилося яскраве світло, яке огорнуло овець. Фурдель відступив назад, затуляючи очі від сліпучого сяйва.",
      "Коли світло згасло, Фурдель побачив, що його вівці їли червоні ягоди, з куща  поруч, і вони змінилися, виглядали зовсім по-інакшому. Тепер вони мали крила, які сяяли всіма кольорами веселки і їх шерсть світилася різними барвами. Вони стали магічними істотами, здатними літати і розмовляти з Фурделем. Так-так, вони літали тепер і балакали. Дуже багато балакали.",
      "-	Дякуємо тобі, Фурделю, за те, що ти привів нас до цього місця, – промовила одна з овець. Ми тепер маємо нові здібності і можемо допомогти тобі і всьому селищу. А ще – можеш називати нас Мінтаврами, бо тепер ми магічні істоти!",
      "Повернувшись додому, Фурдель розповів усім жителям про диво, що сталося в горах. Ніхто правда не повірив, бо як тільки вони спуслилися з гір і настав ранок Мінтаври виглядали як звичайні овечки. Фурдель клявся всім, що з ним трапилося таке диво та ніхто не вірив.",
      "Мінтаври вирішили тримати в таємниці події, що відбулися і лише вночі коли сонце сідало за горами і всі засинали, вони могли розправити свої барвисті крила та знову літати високо-високо у гори. На ранок звісно ж поверталися на своє місце та робили вигляд ніби нічого не відбулося.",
      "Легенда про магічних овець Мінтаврів передавалася з вуст у вуста та не кожен тому вірив. А чи вірите саме Ви у цю пригоду – вирішувати тільки Вам.",
    ],
    background: './img/bg_12.jpg'
  },
  {
    id: 13,
    header: 'Фурдель',
    route: 'the-furdel',
    text: [
      "У селищі Обінор, захованому серед високих скель та квітучих полонин, жив маленький хобіт на ім’я Фурдель. Він був простим хобітом, чиє життя оберталося навколо землеробства та догляду за рослинами й тваринами. Фурдель мав особливий талант: його врожай завжди був найкращим, а його гарбузи завжди найбільші у всьому селищі.",
      "Одного дня в Обінор прийшла біда. Несподівано з’явилися велетенські злі тролі, які вирішили заволодіти усім врожаєм селища. Кажуть, що раз на тисячоліття вони прокидалися в горах та знищували усе їстівне, що могли знайти довкола, таким чином не залишаючи можливості на існування іншим жителям цієї місцевості. Вони витоптували поля та лякали мешканців, а місцеві захисники були безсилі перед їхньою міццю. Селище було у відчаї, бо без врожаю вони не пережили б зиму.",
      "Фурдель не міг дивитися на це спокійно. Він вирішив, що мусить врятувати своє селище. Хобіт згадав стару легенду про, яку читав у бібліотеці - про чарівне зерно, що дає неймовірну силу тим, хто його з'їсть. Це зерно росло на найвищій горі далеко за межами Обінору. Фурдель вирішив піти на пошуки цього зерна, незважаючи на небезпеку, що могла чатувати на нього.",
      'Зі своїм маленьким рюкзаком і великим серцем, Фурдель вирушив у подорож. Він пройшов через густі ліси, перетнув бурхливі річки і піднявся на вершину гори. На своєму шляху він зустрів мудру лісову ельфійку, яка дала йому влучну пораду: "Не тільки сила фізична потрібна для перемоги, а й розум і мужність."',
      "Нарешті Фурдель знайшов чарівне зерно. Він повернувся до селища і, не гаючи часу, з’їв його розділивши з друзями. Його сили зросли в багато разів, але він не відчував себе велетнем. Натомість він став розумнішим і мав надзвичайну хоробрість.",
      "З новими силами та мудрістю, Фурдель розробив хитрий план. Він вирішив використати свої рослини, щоб заманити тролів у пастку: створив велетенський сад із прекрасними, але магічними квітами, які викликали у тролів глибокий сон.",
      "Коли тролі прийшли, Фурдель вміло заманив їх до саду. Після того, як тролі заснули, селяни зв'язали їх і відправили назад у гори, звідки вони прийшли. Обінор був врятований, а Фурдель став героєм свого селища. Його мудрість і відвага назавжди залишилися в пам'яті мешканців, які тепер знали, що навіть найменший серед них може здійснити велике диво.",
      "З того часу, селище Обінор щороку святкувало День врожаю, згадуючи подвиг хобіта Фурделя, який показав, що справжня сила — у серці та розумі.",
    ],
    background: './img/bg_13.jpg'
  },
  {
    id: 14,
    header: 'Чей-че',
    route: 'chey-che',
    text: [
      "У селищі Обінор, серед зелених пагорбів жили незвичайні курочки Чей-че. Ці магічні курочки були не просто домашніми улюбленцями — вони мали особливі здібності, що допомагали жителям селища в їхніх повсякденних турботах.",
      "Кожного ранку, коли сонце лише піднімалося над горизонтом, курочки Чей-че виходили на своє подвір’я і півник починав співати. Його спів був чарівним: він не тільки пробуджував усіх місцевих жителів, але й пробуджував усю флору. Досить було півнику заспівати, як на околицях починали швидко рости зелені паростки, а квіти розцвітали неймовірною красою. Завдяки цій магії, урожай у Обінорі завжди був багатим і соковитим.",
      "Але це був лише початок. Кожна курочка Чей-че мала свою унікальну магічну здатність. Наприклад, одна з них, на ім'я Лумія, могла злітати високо в небо і передбачати погоду. Вона давала селянам поради, коли краще сіяти чи збирати урожай, щоб уникнути негоди. Інша курочка, на ім'я Зоряна, могла приносити яйця в золотих шкарлупках, які високо цінувалися. Ще одна курочка, Ратуга, мала здатність знаходити загублені речі. Досить було лише звернутися до неї з проханням, і вона невдовзі знаходила зникле, або те, що дуже шукаєш.",
      'Одного дня в Обінорі сталася біда. Велика буря знищила більшу частину врожаю, а на селище насунулася комашина хвороба. Жителі були у відчаї. Хобіти зібралися на площі і вирішили попросити допомоги у своїх магічних курочок.',
      "Курочки Чей-че, бачачи сум і тривогу своїх друзів, вирішили об’єднати свої сили. Лумія піднялася в небо і заспівала так, що хмари почали розходитися, відкриваючи сонце. Ратуга вказала Гедзьо на книгу, в якій знаходився рецепт зілля від комашиних напастей. Світляна всю ніч освітлювала понівечені поля, щоб селяни могли зібрати залишки врожаю. Врешті-решт селяни змогли ще висадити нові зерна, а головний півник Чей Че горланив щоранку аби врожай швидше дозрівав.",
      "Завдяки зусиллям курочок Чей-че, селище швидко відновилося. Врожай був врятований, хвороба відступила, а селяни знову посміхалися. Відтоді в Обінорі щороку влаштовували свято на честь магічних курочок Чей-че, які показали, що справжня магія — це допомога, дружба і спільна праця.",
      "Так курочки Чей-че залишилися вірними помічниками жителів Обінору, даруючи їм свою магію і турботу. І кожен, хто відвідував це чарівне селище, не міг не захоплюватися красою і добротою цих дивовижних створінь.",
    ],
    background: './img/bg_14.jpg'
  },
  {
    id: 15,
    header: 'Золотокопальня Кронів',
    route: 'kronivs-gold-mine',
    text: [
      "У далекому селищі Обінор, захованому серед мальовничих пагорбів та квітучих полонин, жило багато працьовитих хобітів. Вони були відомі своєю гостинністю та любов’ю до землеробства. Проте одного дня їхнє життя змінилося назавжди.",
      "Все почалося, коли молодий хобіт на ім'я Тревін вирішив розширити свій квітучий сад. Розкопуючи землю біля підніжжя великої гори, він випадково виявив старий вхід до печери, прихований під густим шаром ґрунту та корінням. Заінтригований, Тревін вирішив дослідити таємничу печеру. Він взяв із собою ліхтар і кількох друзів, серед яких був мудрий старець Гільдрон.",
      "Глибоко всередині гори, хобіти знайшли дивовижне місце — старовинну шахту, в якій усе блищало від золотих жил. Тревін і його друзі не могли повірити своїм очам. Вони зрозуміли, що знайшли величезне багатство, скарб Кронів, який міг змінити їхнє життя та життя всього селища.",
      "З радісною новиною вони повернулися в Обінор. Увесь день і ніч хобіти святкували та обговорювали плани щодо нововідкритої шахти. Гільдрон, як наймудріший серед них, запропонував, щоб золото використовували на благо всього селища: покращити дороги, збудувати нові будинки, побудували бібліотеку та інше благо, бо існувала легенда, що це золото племені Кронів, таємничого поселення з глибини гір, яке зачаклувало свої багатства: золото існувало в руках лише тих людей, які витрачали його на суспільне благо. А якщо ти хотів витратити його на особисті потреби – воно перетворювалося на пісок.",
      "З першими променями сонця хобіти розпочали роботу в шахті. Вони працювали разом, ділилися завданнями та допомагали один одному. Завдяки своїй працьовитості та згуртованості, вони швидко накопичили значний запас золота. Обінор процвітав і його мешканці стали жити ще щасливіше.",
      "Проте золото принесло не лише багатство, але й випробування. Хобіти мали навчитися управляти своїми новими ресурсами з мудрістю. Гільдрон постійно нагадував усім про важливість підтримувати традиції та залишатися згуртованими.",
      "Та все ж виявився один хобіт, який щодня прокидався серед піщаних брил і ніхто не розумів звідки ті піщинки з’являються, бо звечора їх же не було. І лише мудрий Гільдрон знав чому цьому хобіту так не щастило: вся біда була в тому, що Тревін звечора ховав собі декілька злитків до кишені, а вранці прокидався серед піску замість захованого золота. Так відбувалося століттями, до поки Тревін не схаменувся і не перестав брати не своє собі.",
      "Завдяки відкриттю шахти, селище Обінор стало відомим далеко за його межами. Люди з інших країн приїжджали, щоб побачити процвітаюче селище хобітів і повчитися у них мудрості та працьовитості. Хобіти не лише зберегли свій спосіб життя, але й примножили його, перетворивши своє селище на символ єдності та благополуччя.",
      "Історія про шахту та її відкриття передавалася з покоління в покоління, нагадуючи всім хобітам про важливість праці, взаємодопомоги та мудрості в управлінні багатством.",
    ],
    background: './img/bg_15.jpg'
  },
]